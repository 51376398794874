@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&family=Playfair+Display:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&family=Playfair+Display:wght@600&display=swap");

@import url(./colors.css);
@import url(./fonts.css);

@import url(./side-navbar.css);
@import url(./tabs.css);
@import url(./button.css);
@import url(./input.css);
@import url(./label.css);
@import url(./card.css);
@import url(./header.css);
@import url(./table.css);
@import url(./dropdown.css);
@import url(./form.css);
@import url(./pagination.css);
@import url(./modal.css);
@import url(./toggler.css);
@import url(./list.css);
@import url(./scroll-down.css);
@import url(./toggle-switch.css);
@import url(./otp-box.css);
@import url(./tooltip.css);
@import url(./utils.css);

@import url(./components/categories.css);
@import url(./components/overview.css);
@import url(./components/orders.css);
@import url(./components/stock.css);
@import url(./components/login.css);
@import url(./components/auth.css);
@import url(./components/settings.css);
@import url(./components/payment-settings.css);
@import url(./components/color-selector.css);
@import url(./components/account-settings.css);
@import url(./components/settings-nav-bar.css);
@import url(./components/customers.css);
@import url(./components/abandoned-carts.css);
@import url(./components/user-behavior.css);
@import url(./components/error-boundary.css);
@import url(./components/loyalty-program.css);
@import url(./components/promo-codes.css);
@import url(./components/page-views.css);
@import url(./components/sort-search.css);
@import url(./components/percentage-change.css);
@import url(./components/advanced-filters.css);
@import url(./components/applied-filters.css);
@import url(./components/public-website.css);
@import url(./components/create-segment.css);
@import url(./components/create-campaign.css);
@import "react-toastify/dist/ReactToastify.css";

body {
  margin: 0;
  font-family: "NeueMontreal", sans-serif;
  background-color: var(--background-primary);
  box-sizing: border-box;
  overflow-x: hidden;
}
.root {
  position: relative;
}

.main-screen {
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 230px 1fr;
  }
}
.main-screen-collapsed {
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 105px 1fr;
  }
}

.responsive-navbar {
  background-color: var(--black);
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  margin-bottom: 20px;
  padding: 16px;

  .burger-menu-icon {
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      fill: #fff;
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-family: "Playfair Display";
    font-size: 25px;
    font-weight: 600;
  }
  @media (min-width: 992px) {
    display: none;
  }
}

.form-container,
.main-section {
  min-height: calc(100vh - 60px);
  grid-column-start: auto;
  padding: 15px;
  @media (min-width: 992px) {
    padding: 30px;
  }
}

.main-section {
  @media (min-width: 992px) {
    grid-column-start: 2;
    margin-inline-start: 0;
  }
  &--title {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 19px;
    text-transform: uppercase;
    color: var(--color-secondary01);
    margin-bottom: 12px;
  }
}

.form-container {
  min-height: calc(100vh - 300px);
  display: grid;
  place-items: center;
}

.loader-wrapper {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  bottom: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  margin: auto;
}

.Toastify__toast-container--top-right {
  right: unset;
  inset-inline-end: 1em;
}
